import React from "react";
import RoundUpProps from "./roundupProps";

function RoundUpTest(props) {
    return (
        <>
            <div id="started" className="container-fixed max-w-6xl m-auto">
                <div className="px-5 md:px-0">
                    <h3>What do I need to get started?</h3>
                    <div className="border-b-2 solid border-yellow-bright pr-6 pt-3 mb-10 w-22 md:w-96 block m-auto" />
                    <div className="grid grid-cols-1 md:grid-cols-4">
                        <RoundUpProps title="1" text="A mobile phone number to log in with. " />
                        <RoundUpProps title="2" text="An address for orders to be picked up at. " />
                        <RoundUpProps title="3" text="A list of items to sell and photos if you have them. " />
                        <RoundUpProps title="4" text="That's it. All the fun customizations can be done after." />
                    </div>
                    <div>
                        <a
                            href="https://tukapp.typeform.com/to/pXKXCB"
                            className="mb-20 mt-12 block m-auto flex items-center justify-center px-4 py-2 cursor-pointer border-solid border-2 border-yellow-bright w-40 rounded-md hover:bg-yellow-bright hover:text-white"
                        >
                            Get started
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RoundUpTest;