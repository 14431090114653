import React from "react";
import Tick from "../assets/yellow-tick.jpg";
import FactsBoxes from "./factsBoxes";

function Facts(props) {
    return (
        <>
            <div id="TUK" className="bg-yellow-light">
                <div className="py-20">
                    <div className="px-5 md:px-0">
                        <h3>Why use TUK?</h3>
                        <div className="border-b-2 solid border-yellow-bright pr-6 pt-3 mb-12 w-40 block m-auto" />
                    </div>
                    <div className="grid-2 max-w-4xl m-auto lg:space-x-6">
                        <FactsBoxes img={Tick} text="Easily setup your shop in 1 minute" />
                        <FactsBoxes img={Tick} text="Sell on Facebook, Line, WhatsApp, Web, and more" />
                    </div>
                    <div className="grid-2 md:grid-cols-2 max-w-4xl m-auto lg:space-x-6">
                        <FactsBoxes img={Tick} text="TUK accepts cash, PromptPay, Bank Transfers, and Credit Card Payments" />
                        <FactsBoxes img={Tick} text="Use your own drivers, our drivers, or a mix of both" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Facts;