import React from "react";
import Merchant from "../assets/transparency.png";
import TickList from "./tickList.js";

function Features2(props) {
    return (
        <>
            <div className="my-12 md:my-28">
                    <div className="grid-2">
                        <img src={Merchant} alt="cartoon of shop" className="block m-auto" />
                        <div className="py-5">
                            <h4>Transparency is key they said</h4>
                            <p className="text-md pb-5">That’s why TUK is the most sustainable technology partner for local sellers. Don’t believe us? Keep reading.</p>
                            <TickList text="No upfront costs" />
                            <TickList text="No time or resource investment" />
                            <TickList text="No lock-in or exclusivity" />
                        </div>
                    </div>
            </div>

        </>
    );
}

export default Features2;