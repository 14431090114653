import React from "react";
import { Route, Switch} from "react-router-dom";
import Home from './pages/home';
import PrivacyPolicy from './pages/terms-and-conditions';
import Terms from './pages/terms-and-conditions'


function App() {
  return (
    <>
    <div>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/terms-and-conditions" exact component={Terms} />
        <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      </Switch>
    </div>
    </>
  );
}

export default App;