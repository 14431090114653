import Nav from '../components/nav.js'
import Banner from '../components/banner.js'
import Logos from '../components/logos.js'
import Facts from '../components/facts.js'
import Features from '../components/features.js'
import RoundUp from '../components/roundup.js'
import RoundUpTest from '../components/getStarted.js'
import Cta from '../components/cta.js'
import { Route, Switch} from "react-router-dom";
import Terms from '../pages/terms-and-conditions'


function Home() {
  return (
    <div className="App">
      <Nav/>
      <Banner/>
      <Logos/>
      <Facts/>
      <RoundUp/>
      <Features/>
      <RoundUpTest/>
      <Cta/>
      <Switch>
        <Route path="/terms-and-conditions" component={Terms} />
      </Switch>
    </div>
  );
}

export default Home;
