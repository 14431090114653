import React from "react";
import Menu from "../assets/menu.png";
import TickList from "./tickList.js";

function FeaturesShop(props) {
    return (
        <>
            <div className="my-12 md:mb-28">
                    <div className="grid-2">
                        <img src={Menu} alt="cartoon of shop" className="block m-auto" />
                        <div className="py-5">
                            <h4>It all started with a menu</h4>
                            <p className="text-md pb-5">Menus or shop catalogs are the first thing customers see, so it’s important that they are just right highlight your products.</p>
                            <TickList text="Set up a multilingual store with a few clicks." />
                            <TickList text="Pick up only? We have you covered. Customers can select delivery, pickup, or dine-in." />
                            <TickList text="TUK is perfect for chains. Based on the customers location, the order will be sent to the closest store or restaurant." />
                            <TickList text="VIP members will get the first discounts or special items." />
                        </div>
                    </div>
            </div>

        </>
    );
}

export default FeaturesShop;