import React from "react";
import TickList from "./tickList.js";
import video from "../assets/deliveries.mp4"

function FeaturesDelivery(props) {
    return (
        <>
            <div className="my-12 md:my-28">
                <div className="grid-2">
                    <div className="py-5 order-last md:order-first">
                        <h4>Deliveries & Order Tracking</h4>
                        <p className="text-md pb-5">Take back control of how you deliver.</p>
                        <TickList title="Deliveries: " text="Run your own deliveries, use TUK, or use both. Your service, your choice." />
                        <TickList title="Order Tracking: " text="Customers will receive a tracking link when they place their order to track their delivery." />
                        <TickList title="Driver App: " text="Got your own drivers? TUK’s Driver App allows them to see where they are going, communicate with customer, and handle payments." />
                        <TickList title="Analytics: " text="Analytics on your sales, customers, and deliveries so you can improve your return rate." />
                    </div>
                    <video
                        autoPlay
                        muted
                        playsInline
                        className="block m-auto"
                    >
                        <source
                            src={video}
                            type="video/mp4"
                        />
                    </video>
                </div>
            </div>

        </>
    );
}

export default FeaturesDelivery;