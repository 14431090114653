import React from "react";

function Emoji(props) {
    return (
        <>
            <div>
                <img src={props.img} width={45} alt="emoji" className="pt-10 md:pt-0" />
                <p className="font-semibold pt-5 pb-2.5 text-center">{props.text}</p>
                <p>{props.description}</p>
            </div>
        </>
    );
}

export default Emoji;