import React from "react";

function RoundUpProps(props) {
    return (
        <>
            <div className="p-5">
                <p className="text-lg font-semibold text-center">{props.title}</p>
                <p className="text-base pt-5 text-center">{props.text}</p>
            </div>
        </>
    );
}

export default RoundUpProps;