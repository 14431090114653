import React from "react";
import Payments from "../assets/payments.png";
import TickListPayments from "./tickListPayments";

function FeaturesPayment(props) {
    return (
        <>
            <div className="my-12 md:my-28">
                    <div className="grid-2">
                        <img src={Payments} alt="different payment methods" className="md:w-10/12 block m-auto" />
                        <div className="py-5">
                            <h4>Do you take AMEX? Yes, we take everything.</h4>
                            <p className="text-md pb-5">Enable different payment methods that make sense for your shop and customers. </p>
                            <TickListPayments text="Cash on delivery" />
                            <TickListPayments text="Digital wallets & PromptPay" />
                            <TickListPayments text="Bank transfers" />
                            <TickListPayments text="Credit cards" />
                        </div>
                    </div>
            </div>

        </>
    );
}

export default FeaturesPayment;