import React from "react";
import Footer from "../assets/roundup.png";
import RoundUpProps from "./roundupProps";

function RoundUp(props) {
    return (
        <>
            <div className="container-fixed max-w-6xl m-auto pt-20">
                <div className="px-5 md:px-0">
                    <h3>From A to Z, it’s All Out of the Box</h3>
                    <div className="border-b-2 solid border-yellow-bright pr-6 pt-3 w-22 md:w-96 block m-auto" />
                    <img src={Footer} alt="dummy content" className="block m-auto md:w-1/2 py-10" />
                    <div className="grid grid-cols-1 md:grid-cols-4">
                    <RoundUpProps title="Ordering" text="Complete control over your menu prices, discounts, shop languages and more. " />                    
                    <RoundUpProps title="Order Management" text="Centralize all your orders in one place wether they are from Facebook, a chat app or the web. " />                    
                    <RoundUpProps title="Delivery Tracking" text="Customers can track their order status and delivery drivers location. " />                    
                    <RoundUpProps title="Driver App" text=" If you have your own drivers, they can use TUK’s driver app so they can easily deliver your orders." />                    
                    </div>
                </div>
            </div>
        </>
    );
}

export default RoundUp;