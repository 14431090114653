import React from "react";
import background from "../assets/background.jpg"
import Footer from "../assets/roundup.png";
import Facebook from "../assets/facebook-black.png";
import Instagram from "../assets/instagram.png";

function Cta(props) {
    return (
        <>
            <div className="bg-yellow-bright" style={{ background: `url(${background})`, backgroundPosition: 'center top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 max-w-5xl m-auto">
                        <div className="px-5 md:px-0">
                            <h1 className="pt-12 md:pt-36 text-3xl font-semibold leading-normal text-center md:text-left">What are you waiting for?</h1>
                            <h2 className="text-2xl leading-normal pb-5 text-center md:text-left">Start selling with TUK, <span className="font-medium">today</span>.</h2>
                            <div>
                                <a
                                    href="https://tukapp.typeform.com/to/pXKXCB"
                                    className="flex bg-black text-white items-center justify-center px-4 py-2 cursor-pointer border-solid border-2 w-40 rounded-md block m-auto md:grid md:m-1"
                                >
                                    Sign up now
                                </a>
                            </div>
                        </div>
                        <div>
                            <img src={Footer} alt="cartoon of shop" className="block m-auto pt-10 md:pt-16" />
                        </div>
                    </div>
                    <div className="max-w-5xl m-auto py-10 px-5 md:px-0">
                        <p className="float-left font-semibold text-lg">Follow us:</p><a href="https://www.facebook.com/tukapp" target="_blank" rel="noreferrer"><img src={Facebook} alt="icon of facebook" className="w-9 pl-2 float-left" /></a><a href="https://www.instagram.com/tuk_app/" target="_blank" rel="noreferrer"><img src={Instagram} alt="icon of facebook" className="w-9 pl-2" /></a>
                        <p className="text-center pt-5"><a href="https://tuk-website.sgp1.digitaloceanspaces.com/Tuk%20-%20Privacy%20Policy.pdf" rel="noreferrer" target="_blank">Privacy Policy</a> I <a href="https://tuk-website.sgp1.digitaloceanspaces.com/Tuk%20Terms%20of%20Service.pdf" rel="noreferrer" target="_blank">Terms and Conditions</a> I <a href="mailto:info@tukapp.co" rel="noreferrer">Questions?</a></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cta;