import React from "react";
import yellowTick from "../assets/yellowTick.jpg";

function TickList(props) {
    return (
        <>
            <div className="flex py-2">
                <img src={yellowTick} alt="yellow tick" className="w-7 h-7" />
                <p className="floa-left font-bold ml-2">{props.title}<span className="font-light">{props.text}</span></p>
            </div>
        </>
    );
}

export default TickList;