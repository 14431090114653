import React from "react";
import subway from "../assets/subway.png"
import dinky from "../assets/dinkys_bbq.png"
import japanese from "../assets/musashi_sushi.png"
import salsa from "../assets/salsa_kitchen.png"
import squiggle from "../assets/homduan.png"
import donut from "../assets/donut_cafe.png"
import butter from "../assets/butter_is_better.png"
import burger from "../assets/beast_burger.png"

function Logos(props) {
    return (
        <>
            <div className="py-10">
                <div className="px-10 md:px-0">
                    <h3>Join 500+ Shops, Restaurants, and Service Providers</h3>
                </div>
                <div className="flex flex-wrap justify-center m-auto py-6 space-x-6">
                    <img src={subway} alt="Tuk Logo"/>
                    <img src={dinky} alt="Tuk Logo"/>
                    <img src={japanese} alt="Tuk Logo"/>
                    <img src={salsa} alt="Tuk Logo"/>
                    <img src={burger} alt="Tuk Logo"/>
                    <img src={butter} alt="Tuk Logo"/>
                    <img src={squiggle} alt="Tuk Logo"/>
                    <img src={donut} alt="Tuk Logo"/>
                </div>
            </div>
        </>
    );
}

export default Logos;