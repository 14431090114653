import React from "react";
import logo from '../assets/logo.png';
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";


function Navbar({ fixed }) {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const hide = (e) => {
        if (e && e.relatedTarget) {
            e.relatedTarget.click();
        }
        setNavbarOpen(false);
    }
    return (
        <>
            <nav className="bg-yellow-bright relative flex flex-wrap lg:flex-nowrap items-center justify-between px-2 py-3 m-auto">
                <div className="max-w-7xl m-auto container flex flex-wrap lg:flex-nowrap items-center justify-between">
                    <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                        <div>
                            <NavLink activeClassName="active" exact to="/">
                                <img className="w-20 mt-3 md:mx-24" src={logo} alt="Logo" />
                            </NavLink>
                        </div>
                        <button
                            className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                            onBlur={hide}
                        >
                            <i className="fa fa-bars"></i>
                        </button>
                    </div>
                    <div
                        className={
                            "lg:flex flex-grow items-center" +
                            (navbarOpen ? " flex" : " hidden")
                        }
                    >
                        <div className="flex flex-col w-full md:w-auto lg:flex-row list-none justify-evenly mt-2.5">
                            <Link activeClass="active"
                                to="TUK"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}><p className="cursor-pointer	px-10 py-2 justify-center flex items-center text-md font-semibold leading-snug text-black hover:opacity-75"
                                    activeClassName="active" exact to="/tuk">
                                    <span>Why use Tuk?</span>
                                </p></Link>
                                <Link activeClass="active"
                                to="features"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}><p className="cursor-pointer px-12 py-2 justify-center flex items-center text-md font-semibold leading-snug text-black hover:opacity-75"
                                activeClassName="active" exact to="/features">
                                <span>Features</span>
                            </p></Link>
                                <Link activeClass="active"
                                to="started"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}><p className="cursor-pointer px-12 py-2 justify-center flex items-center text-md font-semibold leading-snug text-black hover:opacity-75"
                                activeClassName="active" exact to="/features">
                                <span>Get started</span>
                            </p></Link>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;