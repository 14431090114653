import React from "react";
import App from "../assets/hero-app.png"
import background from "../assets/background.jpg"
import appstore from "../assets/appstore.png"
import googleplay from "../assets/googleplay.png"


function Banner(props) {
    return (
        <>
            <div className="bg-yellow-bright" style={{ background: `url(${background})`, backgroundPosition: 'center top', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }}>
                <div>
                    <div className="grid-2 max-w-5xl m-auto pt-12 md:py-0">
                        <div className="px-5 md:px-0">
                            <h1 className="md:pt-20 text-4xl font-semibold leading-normal text-center md:text-left">The easiest way to sell locally</h1>
                            <h2 className="text-3xl leading-normal text-center md:text-left">Set up your online shop & deliveries in minutes</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 m-auto pt-6 py-6">
                                <a href="https://tukapp.co/install" target="_blank" rel="noreferrer"><img src={appstore} alt="Tuk App" className="w-52 pb-5 md:pb-0 block m-auto md:grid md:m-1" /></a>
                                <a href="https://tukapp.co/install" target="_blank" rel="noreferrer"><img src={googleplay} alt="Tuk App" className="w-52 block m-auto md:grid md:m-1" /></a>
                            </div>
                        </div>
                        <div>
                            <img src={App} alt="Tuk App" className="block m-auto max-w-xs" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Banner;