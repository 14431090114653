import React from "react";
import moneyTick from "../assets/money.png";

function TickList(props) {
    return (
        <>
            <div className="flex py-2">
                <img src={moneyTick} alt="yellow tick" className="w-7 h-7" />
                <p className="floa-left font-medium ml-2">{props.text}</p>
            </div>
        </>
    );
}

export default TickList;