import React from "react";

function FactsBoxes(props) {
    return (
        <>
            <div className="border border-yellow-bright solid rounded-lg p-10 mb-6 mx-5 bg-white">
                <img src={props.img} width={32} className="float-left" alt="Yellow tick" /><p>{props.text}</p>
            </div>
        </>
    );
}

export default FactsBoxes;