import React from "react";
import FeatureShop from "./featureShop.js"
import FeaturesDelivery from "./featuresDelivery"
import Features1 from "./feature1.js"
import Features2 from "./feature2.js"
import FeaturesPayment from "./featuresPayment";

function Features(props) {
    return (
        <>
            <div id="features" className="container-fixed max-w-5xl m-auto py-20">
                <div className="px-5 md:px-0">
                    <h3>Features</h3>
                    <div className="border-b-2 solid border-yellow-bright pr-6 pt-3 w-40 block m-auto" />
                </div>
                <FeatureShop />
                <Features1 />
                <FeaturesPayment />
                <FeaturesDelivery />
                <Features2 />
            </div>
        </>
    );
}

export default Features;