import React from "react";
import Web from "../assets/web.png";
import Facebook from "../assets/facebook.png";
import Chat from "../assets/chat.png";
import Emoji from "./emoji.js";
import tukChannel from "../assets/tukchannels.mp4"


function Features1(props) {
    return (
        <>
            <div className="my-12 md:my-28">
                    <div className="grid-2">
                        <div className="py-5 md:py-10 order-last md:order-first">
                            <h4>Sell on any channel, all day everyday</h4>
                            <p className="text-md">Buyers are everywhere: online, offline, web, mobile, social, chat, app - TUK works on all channels, at no extra costs.</p>
                            <div>
                                <div className="flex flex-wrap justify-between md:pt-10 max-w-sm">
                                    <Emoji img={Facebook} text="Social" />
                                    <Emoji img={Chat} text="Chat" />
                                    <Emoji img={Web} text="Web" />
                                </div>
                            </div>
                        </div>
                        <video
                        autoPlay
                        muted
                        playsInline
                        className="block m-auto md:w-3/4 md:pl-10"
                    >
                        <source
                            src={tukChannel}
                            type="video/mp4"
                        />
                    </video>
                    </div>
            </div>

        </>
    );
}

export default Features1;