import Cta from '../components/cta.js'

function termsandconditions() {
    return (
        <div>
            <div className="max-w-5xl m-auto py-10">
                <h1 className="font-semibold text-2xl pb-5">Terms & Conditions
                </h1>
                <p>
                    TUK and their associates are providing their services to you subject to the following terms, which are also known as our terms and conditions (“Terms of Use”). By gaining access and choosing to peruse this website, you accept and agree to these Terms of Use. If you choose not to agree to these Terms of Use, you are advised to discontinue use of this website.
                    <br>
                    </br>
                    <br>
                    </br>
                    TUK is a trading name of ALLOPATRA CO., LTD., a Thai registered company. If you need to contact a TUK representative please use a method on our ‘Help’ section of our website. TUK reserves the right to change and alter these terms without giving prior notice and it is up to the user of the website to take responsibility to return to this page to review any changes that have been made. These changes take effect from the exact time of posting on this website. It is advised before the purchase of any product that you review this section. The last time these Terms and Conditions were altered was on the 1st of July 2021.
                    <br>
                    </br>
                    <br>
                    </br>
                    PRIVACY POLICY
                    <br>
                    </br>
                    <br>
                    </br>
                    TUK enforces a strict privacy policy which every employee of TUK must comply with.  TUK will not disclose information of its customers to any third party. In the event of a sale, merger or acquisition of TUK company, TUK will transfer customer details to the new owners. The transfer of the information will be dealt with appropriately and in the most secure form possible.
                    <br>
                    </br>
                    <br>
                    </br>
                    PRODUCT AVAILABILITY AND PRICES
                    <br>
                    </br>
                    <br>
                    </br>
                    TUK will attempt to make the product and quantities advertised on this site available at all times. However, this cannot always be guaranteed and TUK will not be liable for any products that are not available at the time of your checkout. The customer will be notified as soon as possible when this occurs and advised on what to do to solve this problem. TUK’s prices are subject to change without notice. TUK reserves the right to refuse or terminate/cancel orders when there has been website malfunction or error such as technical difficulty or an incorrect price being displayed. Notification to the customer will be facilitated immediately after this has been notified to us and any payment already received from the customer will be refunded.
                    <br>
                    </br>
                    <br>
                    </br>
                    PROMOTIONS
                    <br>
                    </br>
                    <br>
                    </br>
                    All promotions are subject to availability and while stocks last.
                    <br>
                    </br>
                    <br>
                    </br>
                    RETURNS POLICY
                    <br>
                    </br>
                    <br>
                    </br>
                    TUK will offer a refund, but only within 14 days and only for defective or damaged items.  This refund is only available if all items provided are returned in their original state, and include all packing material, original manuals and instructions, and any accessories that came with them. Partially returned items will NOT be accepted. Once the product has been used, it will not be liable for a refund. It is the customer’s responsibility to pay for shipping
                </p>
            </div>
            <Cta />
        </div>
    );
}

export default termsandconditions;
